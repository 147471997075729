import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const PdfViewer = () => {
  const location = useLocation();
  const [pdfUrl, setPdfUrl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if pdfUrl is passed in the location's state
    if (location.state && location.state.pdfUrl) {
      setPdfUrl(location.state.pdfUrl);
    } else {
      console.error('No PDF URL found in location state');
    }

    // Clean up the URL object when the component is unmounted
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [location.state, pdfUrl]);

  const handleBack = () => {
    navigate(-1); // Mimic the browser's back button
  };

  return (
    <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
      {/* Home Icon positioned absolutely in the top-left corner, close to the PDF viewer */}
      <IconButton
        onClick={() => navigate('/')}
        color="primary"
        style={{
          position: 'absolute',
          top: '10px',
          left: '10%', // Adjusted left spacing
          zIndex: 1,
        }}
      >
        <HomeIcon />
      </IconButton>

      {/* Back Button positioned on the right */}
      <Button
        variant="contained"
        color="primary"
        startIcon={<ArrowBackIcon />}
        onClick={handleBack} // Mimic the browser's back button
        style={{
          position: 'absolute',
          top: '10px',
          right: '10%',
          zIndex: 1,
        }}
      >
        Back to Editor
      </Button>

      {/* Centered PDF Viewer */}
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '20px',
        }}
      >
        {pdfUrl ? (
          <iframe
            src={pdfUrl}
            title="PDF Viewer"
            style={{ width: '80%', height: '90vh', border: 'none' }}
          />
        ) : (
          <p>Loading PDF...</p>
        )}
      </div>
    </div>
  );
};

export default PdfViewer;
