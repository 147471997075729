// src/App.js

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./components/Login";
import PdfUploader from './components/PdfUploader';
import { Route, Routes } from "react-router-dom"; // Import only Routes and Route
import SplitScreenResume from "./components/SplitScreenResume";
import PdfViewer from './components/PdfViewer'; 
import './App.css';

function App() {
  const { isAuthenticated } = useAuth0();

  return (
    <div className="App">
      {!isAuthenticated ? (
        <LoginButton />
      ) : (
        <Routes>
          <Route path="/" element={<PdfUploader />} />
          <Route path="/split-screen" element={<SplitScreenResume />} />
          <Route path="/pdf-viewer" element={<PdfViewer />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
